.newPlateForm {
  width: 100%;
  padding: 4px;
  padding-bottom: 6px;
  background-color: #1F2125;
  margin-bottom: 10px;
}

.newPlateForm form {
  margin-bottom: 0;
}