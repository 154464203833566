.plateInspector {
  position: absolute;
  right: 4px;
  top: 82px;
  width: 350px;
  min-height: 625px;
  border: 1px solid #888;
  border-radius: 4px;
  background-color: white;
  color: black;
}

.plateInspector input.steamlined {
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);
}

.plateInspector .content {
  padding: 10px;
}

.plateInspector section.layers {
  margin-top: 6px;
  border: 1px solid #D5D5D5;
  background-color: #EEE;
  border-radius: 4px 4px 0 0;
  height: 150px;
  overflow-y: auto;
}

.plateInspector section.layers .header {
  padding: 6px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #D5D5D5;
}

.plateInspector .layer {
  padding: 3px;
  border: 2px solid #333;
  border-top: 2px solid #555;
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plateInspector .layer input {
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);
  color: white;
}


.plateInspector .layer:hover {
  background-color: #4066b1b6;
  border: 2px solid rgba(90, 120, 240, 0.5);
  cursor: pointer;
}

.plateInspector .layer.selected {
  border: 2px solid rgba(90, 120, 240, 0.8);
  background-color: #4066b1;
  box-shadow: rgba(30, 30, 235, 0.4) 0px 3px 13px 0px;
}

.plateInspector section {
  margin-bottom: 6px;
}

.plateInspector section.wells .header {
  padding: 6px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #D5D5D5;
}

.plateInspector section.wells {
  margin-top: 6px;
  border: 1px solid #D5D5D5;
  background-color: #EEE;
  border-radius: 4px 4px 0 0;
  height: 370px;
  overflow-y: auto;
}

.plateInspector section.wells table {
  width: 100%;
  color: black;
  font-size: 0.8em;
}

.plateInspector section.wells input {
  width: 77px;
}

.addLayerButton:hover {
  cursor: pointer;
  color: #4066b1b6;
}

.layers .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layer .fa-trash {
  color: #abaad1;
}

.layer .fa-trash:hover {
  color: #ff0000;
}