.plateSet {
  width: 488px;
  position: relative;
  border: 0;
  border-radius: 7px;
  background-color: #414445;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  filter: drop-shadow(5px 5px 8px #000000);
  border: 3px solid rgba(80, 80, 80, 0.8);
  min-width: 150px;
  min-height: 50px;
  background-color:  rgba(139, 141, 144, 0.8);
  display: flex;
  flex-direction: column;
}

.plateSet > .header {
  height: 22px;
  border-radius: 3px 3px 0 0;
  background-color: rgba(90, 90, 90, 0.8);
  color: white;
  padding: 2px 4px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 2px rgba(10, 10, 10, 0.8);
}

.plateSet > .header .left {
  flex: 1;
  text-align: left;
}
.plateSet > .header .right {
  flex: 1;
  text-align: right;
}

.plateSet.selected {
  border: 3px solid rgba(90, 120, 240, 0.8);
  background-color: #75d7f0ab;
  box-shadow: rgba(30, 30, 235, 0.8) 0px 7px 29px 0px;
  z-index: 1000;
}

.plateSet.selected > .header {
  background-color: rgba(70, 70, 70, 0.8);
}

.plateSet .close-icon {
  color:rgba(157, 157, 157, 0.8);
  cursor: pointer;
  margin: 4px 6px;
}

.plateSet .header p {
  margin: 0;
  padding: 4px 6px;
}

.plateSet .close-icon:hover {
  color:rgba(221, 221, 221, 0.8);
}


.plate {
  /* height: 320px; */
  position: relative;
  border: 0;
  border-radius: 7px;
  background-color: rgba(139, 141, 144, 0.8);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 7px;
  margin-top: 3px;
  /* filter: drop-shadow(5px 5px 8px #000000); */
  border: 3px solid rgba(80, 80, 80, 0.8);
  min-width: 150px;
  min-height: 50px;
  aspect-ratio: 3/2;
}

.plate .layer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: calc(100% - 28px);
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  container-type: inline-size;
}

.well {
  display: flex;
  flex: 1 1 0px;
  min-width: 0;
  overflow-x:hidden;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  font-size: 0.9em;
  border-radius: 20%;
  background-color: #282c34;
  color: white;
  user-select: none;
  font-size: 0.4em;
}

.well {
  font-size: 1em;
}

@container (min-width: 300px) {
  .well {
    font-size: 0.4em;
  }
}

@container (min-width: 400px) {
  .well {
    font-size: 0.9em;
  }
}

@container (min-width: 500px) {
  .well {
    font-size: 1.2em;
  }
}

@container (min-width: 600px) {
  .well {
    font-size: 1.5em;
  }
}


.plate .header {
  height: 15px;
  border-radius: 3px 3px 0 0;
  background-color: rgba(90, 90, 90, 0.8);
  color: white;
  padding: 4px;
  margin-bottom: 4px;
  text-overflow: ellipsis;

  box-shadow: 0 2px 2px rgba(10, 10, 10, 0.8);
}

.plateSet.selected .plate.selected {
  border: 3px solid rgba(90, 120, 240, 0.8);

  box-shadow: rgba(30, 30, 235, 0.8) 0px 7px 29px 0px;
}

.plateSet.selected  .plate.selected .header {
  background-color: rgba(70, 70, 70, 0.8);
}

.plate:not(.selected) > .header .fa-circle-check {
  visibility: hidden;
}

.plateSet:not(.selected) > .header .fa-circle-check {
  visibility: hidden;
}

/* Also don't indicate that a plate is selected if it's
   plateSet is not selected.
*/
.plateSet:not(.selected) .plate > .header .fa-circle-check {
  visibility: hidden;
}

.plateSet .header .fa-circle-check {
  color: #7bd57b;
}


.plate .layer.layout .well {
  background-image: linear-gradient(45deg, #B2A2CD88 27.78%, #9a85b888 27.78%, #9a85b888 50%, #B2A2CD88 50%, #B2A2CD88 77.78%, #9a85b888 77.78%, #9a85b888 100%);
  background-size: 12.73px 12.73px;
}

/* .plate .layer.layout .well .label {
  transform: rotate(-45deg);
} */
