.platePanel {
  height: calc(100% - 200px);
  min-height: 600px;
  width: calc(100% - 80px - 385px);
  margin-left: 80px;
  margin-right: 385px;
  border: 1px solid #777;
  position: relative;
  background-image: url("../../../app/assets/grid-background.png");
  background-repeat: repeat;
  overflow: auto;
  padding: 10px;
  padding-top: 15px;
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.65) 0px -50px 36px -28px inset, rgba(0, 0, 0, 0.65) 0px 28px 36px -28px inset;

}

.dropzone .uploadIcon {
  display: none;
  position: absolute;
  left: 45%;
  top: 32%;
  opacity: 0.3;
  color: #4d7cff;
}

.dropzone.dz-drag-hover .uploadIcon {
  display: block;
}