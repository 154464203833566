.window {
  filter: drop-shadow(5px 5px 8px #000000);
  border-radius: 10px;
}

.window .header {
  background-color: rgba(228, 224, 224, 0.8);
}

.window > .header {
  border-radius: 3px 3px 0 0;
  background-color: rgba(70, 70, 70, 0.8);
  color: white;
  padding: 4px;
  margin-bottom: 4px;
  box-shadow: 0 2px 2px rgba(10, 10, 10, 0.8);
}
