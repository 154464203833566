html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #282c34;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 3px;
  color: white;
  font-size: 0.8em;
}
/* 
.blk {
  width: 100px;
  height: 100px;
} 
ColorService.darkColors().forEach((color) => { $('.platePanel').append(`<div style='background-color: ${color};'>${color}</div>`); });
*/
