.menubar {
  display: flex;
  background-color: white;
  padding: 3px;
  border-radius: 6px;
  box-shadow: 0 2px 10px var(--black-a7);
  margin-bottom: 3px;
  color: #555375;
}

/* All items, top level and in menu */
.menubar .item {
  position: relative;
  display: inline-block;
  padding: 8px 12px;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  color: var(--violet-11);
  font-size: 13px;
  display: flex;
  align-items: left;
  gap: 2px; 
}

/* Top level items... */
.menubar > .item {
  align-items: center;
  justify-content: space-between;
}

.menubar .item:hover {
  background-color: rgba(90, 120, 240, 0.2);
  cursor: pointer;
}

.menubar .item .content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 8px 6px;
  z-index: 2000;
  top: 29px;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.menubar .item:hover .content {
  display: block;
}

.menubar .item i {
  margin-right: 4px;
}