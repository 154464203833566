


.well:hover {
  background-color: #4066b1;
  cursor: pointer;
}

/* This is used while the dragging is happening */
.well.selecting {
  border: 1px solid #EEE;
  background: 
      linear-gradient(90deg, #333 50%, #FFF 0) repeat-x,
      linear-gradient(90deg, #333 50%, #FFF 0) repeat-x,
      linear-gradient(0deg, #333 50%, #FFF 0) repeat-y,
      linear-gradient(0deg, #333 50%, #FFF 0) repeat-y;
  background-size: 6px 2px, 6px 2px, 2px 6px, 2px 6px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  /* animation: linearGradientMove .7s infinite linear; */
}

.plateSet.selected .well.selected {
  border: 1px solid #EEE;
  background: 
      linear-gradient(90deg, #333 50%, #FFF 0) repeat-x,
      linear-gradient(90deg, #333 50%, #FFF 0) repeat-x,
      linear-gradient(0deg, #333 50%, #FFF 0) repeat-y,
      linear-gradient(0deg, #333 50%, #FFF 0) repeat-y;
  background-size: 6px 2px, 6px 2px, 2px 6px, 2px 6px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  animation: linearGradientMove .7s infinite linear;
}

@keyframes linearGradientMove {
  100% {
      background-position: 6px 0, -6px 100%, 0 -6px, 100% 6px;
  }
}

/* Selected well animated border */
/* @keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.well.selected {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.well.selected::before {
  content: '';
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: #99399900;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#0a6cffc0, #0a6cff00), linear-gradient(#f1ecffa1, #550ce700);
  animation: rotate 2s linear infinite;
}

.well.selected::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: rgba(0,0,0,0);
  border-radius: 20%;
} */
