.plateMapping {
  right: 4px; 
  top: 82px;
  width: 350px;
  height: 650px;
  background-color: #FFFFFF;

  z-index: 10000;
  position: absolute;
  color: black;

  border-radius: 4px;
}

.plateMapping .content {
  padding: 7px;
}

.plateMapping .footer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.plateMapping .repeatDefinition {
  clear: both;
  margin-top: 10px;
}

.plateMapping .formRow {
  display: flex;
  justify-content: flex-start;
  margin: 4px 2px;
}

.plateMapping .formRow label {
  width: 85px;
}

.mappingConnector {
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
  position: absolute;
}

.mappingConnector:hover {
  background-color: #EEEEEE;
  border-color: red;
  cursor: pointer;
}

.mappingConnector.right {
  right: -8px;
  top: 51.3%;
}

.mappingConnector.left {
  left: -8px;
  top: 51.3%;
}

.mappingConnector.top {
  left: 48.5%;
  top: -10px;
}

.mappingConnector.bottom {
  left: 48.5%;
  bottom: -10px;
}

.plateMapping section {
  margin-top: 6px;
  border: 1px solid #D5D5D5;
  background-color: #EEE;
  border-radius: 4px 4px 0 0;
}

.plateMapping .header {
  padding: 6px;
  border-radius: 4px 4px 0 0;
}

.plateMapping section label {
  padding: 4px;
}

.plateMapping section {
  padding-bottom: 8px;
}

.myArrowClass {
  z-index: 2000;
}

.mappingConnector.dropenter {
  background-color: #0cb739;
  border: 3px solid rgba(28, 229, 51, 0.992);
}