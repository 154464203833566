.toolbar {
  position: absolute;
  left: 4px;
  top: 13px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:start; 
  padding: 1px;
  width: 72px;
  height: 400px;
  min-height: max-content;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 2px 10px var(--black-a7);
  margin-top: 70px;
  color: #555375;
  align-content: start;
}

.toolbar .item {
  /* flex: 0 0 auto; */
  color: var(--mauve-11);
  height: 25px;
  display: inline-flex;
  font-size: 13px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid rgba(90, 120, 240, 0.0);
  padding: 4px;
  width: 20px;
  height: 20px;
  margin: 2px;
}

.toolbar .item:hover {
  background-color: rgba(90, 120, 240, 0.2);
  cursor: pointer;
}


.toolbar .item.selected {
  border: 2px solid rgba(90, 120, 240, 0.8);
  background-color: rgba(90, 120, 240, 0.2);
  cursor: pointer;
}
