.openPlatesModal {
  height: 400px;
  width: 600px;
  background-color: #FFFFFF;
  
  z-index: 10000;

  position: absolute;
  top: 130px;
  left: 50%;
  transform: translate(-50%, 0%);
  
  color: black;
}

.openPlatesModal .options {
  padding: 10px;
  height: 315px;
  overflow-y: auto;
}

.openPlatesModal .options .option {
  padding: 8px 12px;
  border-radius: 4px;
}

.openPlatesModal .options .option:hover {
  background-color: #5a78f033;
  cursor: pointer;
}

.footer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}